import React from 'react';
import TaskFormProps from '../TaskFormProps';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Grid, Typography } from '@material-ui/core';
import Synth2InputCell from './Synth2InputCell';
import Synth2Store from '../../../../../../../../data/store/pageStore/design/Synth2Store';
import Helper from '../../../../../../../../module/Helper';
import TitledPaper from '../../../../../../../common/component/TitledPaper';
import FormUtil from '../../../../../../../../module/FormUtil';
import FormButtonSet from '../../../../../../../common/component/FormButtonSet';
import PTSMessage from '../../../../../../../../data/model/common/PTSMessage';
import Validatable from '../../../../../../../../data/model/common/Validatable';
import FileWithHelperText from '../../../../../../../common/component/FileWithHelperText';

interface Props extends TaskFormProps, RouteComponentProps<any> {
}

@inject('rootStore')
@observer
class Synth2Form extends React.Component<Props> {
    globalStore = this.props.rootStore.globalStore;
    store = observable(new Synth2Store());
    state = {
        workID: 0,
    }

    componentDidMount = async () => {
        try {
            this.globalStore.isRunning = true;
            const workInfo = this.props.workInfo;
            this.store.workInfo = workInfo;
            this.store.taskData._id = (workInfo.taskList[0] as any)._id;
            this.store.taskData.workID = (workInfo.taskList[0] as any).workID;
            await this.store.setChipInfos(this.globalStore.token);
            this.props.rootStore.commentStore.stage = '합성 Ph.2';
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        } finally {
            this.globalStore.isRunning = false;
        }
    }

    getFileUploadForm = (): FormData => {
        const formData = new FormData();
        const fe = Array.from(this.store.files.values());
        for (const file of fe) {
            formData.append('files', file);
        }
        return formData;
    }

    onFileChange = (name: string, files: FileList) => {
        const file = files[0];
        const uniqueKey = Helper.generateRandomString();
        const ptsFileObj = Helper.generatePTSFileWithUniqueKey(file, uniqueKey);
        this.store.files.set(name, file);
        this.store.filesMapping.set(name, ptsFileObj);
        return ptsFileObj;
    }

    onValidate = () => {
        this.store.taskData.detail.initErrorsRecursive();
        this.store.taskData.detail.resetErrorsRecursive();
        this.store.taskData.detail.validateRecursive();
        this.store.isValid = !this.store.taskData.detail.hasErrorRecursive();

        for (let chipInfo of this.store.chipInfos) {
            chipInfo.initErrorByKey('PCRConcentration');
            chipInfo.resetErrorsRecursive();
            chipInfo.validateRecursive();
            if (chipInfo.hasErrorRecursive()) {
                this.store.isValid = !chipInfo.hasErrorRecursive();
            }
        }

        if (!this.store.isValid) {
            alert(Validatable.errorTexts.notValid);
        }
    }

    onFormClear = () => {
        window.location.reload();
    }

    onFormSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        try {
            if (!window.confirm('제출하시겠습니까?')) {
                return;
            }
            this.globalStore.isRunning = true;

            // upload files
            const files = this.store.getFileUploadForm();
            if (files) {
                const fileResult = await this.store.uploadFiles(files, this.globalStore.token);
                const mapping = Array.from(this.store.filesMapping.values());

                for (let i = 0; i < fileResult.data.data.length; i++) {
                    mapping[i].file_id = fileResult.data.data[i];
                }
            }

            const chipResult = await this.store.replaceChipInfos(this.globalStore.token);
            if (chipResult.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: chipResult.data.resMessage,
                })
            }

            this.props.postTaskData(this.store.taskData);
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        } finally {
            this.globalStore.isRunning = false;
        }
    }

    render() {
        return (
            <React.Fragment>
                <Grid item container>
                    <Grid item xl lg sm>
                        <TitledPaper title='Files'>
                            <Grid item container xl lg sm style={{ padding: 10 }}>
                                <Grid item xl={4} lg={4} sm={4} style={{ marginRight: 10 }}>
                                    <Typography variant='body1'>
                                        {FormUtil.getFieldLabel('usageReport')}
                                    </Typography>
                                </Grid>
                                <Grid item xl lg sm>
                                    <FileWithHelperText
                                        name='usageReport'
                                        multiple={false}
                                        onFileChange={this.onFileChange}
                                        data={this.store.taskData.detail}
                                        helperText={
                                            Helper.getErrorText(
                                                this.store.taskData.detail.errors.usageReport
                                            )
                                        }
                                        error={
                                            Helper.getErrorState(
                                                this.store.taskData.detail.errors.usageReport
                                            )
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </TitledPaper>
                        <Grid item xl lg sm>
                            <TitledPaper title='Chip Infos'>
                                <Grid xl lg sm item container direction='column'>
                                    {this.store.chipInfos.map((v, i) => (
                                        <Synth2InputCell data={v} index={i} key={i} />
                                    ))}
                                </Grid>
                            </TitledPaper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xl lg sm>
                    <FormButtonSet
                        validated={this.store.isValid}
                        onFormClear={(e) => this.onFormClear()}
                        onSubmit={(e) => this.onFormSubmit(e)}
                        onValidate={(e) => this.onValidate()} />
                </Grid>
            </React.Fragment>
        )
    }
}

export default withRouter(Synth2Form);