import { Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import React from 'react';
import ManufactureItem from '../../../../../../data/model/manufacture/ManufactureItem';
import ManufactureWork from '../../../../../../data/model/manufacture/ManufactureWork';
import FormUtil from '../../../../../../module/FormUtil';
import logo from '../../../../../../resource/img/celemics_logo.png';

interface Props {
    data?: ManufactureWork;
}

const tablePadding = 2;
const tableMarginTop = 5;
const tableTitleFontSize = 18;
const tableValueFontSize = 14;

const titleStyle = {
    background: '#e2e2e2',
    textAlign: 'center' as 'center',
    border: '1px solid',
    width: '100%',
    verticalAlign: 'center' as 'center',
}

const mainTable = {
    marginTop: tableMarginTop,
    borderCollapse: 'collapse' as 'collapse',
    width: '100%',
}

const tdItemKeyStyle = {
    background: '#e2e2e2',
    border: '1px solid',
    padding: tablePadding,
    textAlign: 'center' as 'center',
    fontSize: tableValueFontSize,
}

const tdItemValueStyle = {
    border: '1px solid',
    padding: tablePadding,
    textAlign: 'center' as 'center',
    fontSize: tableValueFontSize,
}

// const tdPadding = {
//     padding: tablePadding,
// }

const trPageBreak = {
    pageBreakAfter: 'auto' as 'auto',
    pageBreakInside: 'avoid' as 'avoid',
}

const docNO = 'CLM-F701-06 (Rev. 13)'

const keyValueItemCell = (key: string, value: any) => {
    return (
        <>
            <td style={tdItemKeyStyle}>
                {FormUtil.getFieldLabel(key ?? '')}
            </td>
            <td style={tdItemValueStyle}>
                {value ?? ''}
            </td>
        </>
    )
}

const itemCell = (data: ManufactureItem, index: number): JSX.Element => {
    return (
        <React.Fragment key={index}>
            <tr style={trPageBreak} key={index}>
                <td style={tdItemValueStyle}>
                    {`${index + 1}`}
                </td>
                <td style={tdItemValueStyle}>
                    {data.itemOptions.translatePlatform()}
                </td>
                <td style={tdItemValueStyle}>
                    {data.itemName}
                </td>
                <td style={tdItemValueStyle}>
                    {`${data.numberPerQty} ${data.unit === 'rxn' ? 'Sample(s)' : data.unit === 'ml' ? 'ml' : ''}`}
                </td>
                <td style={tdItemValueStyle}>
                    {data.qty}
                </td>
                <td style={tdItemValueStyle}>
                    {data.pooling}
                </td>
                <td style={tdItemValueStyle}>
                    {data?.hybRxn ? (isNaN(data?.hybRxn) ? '' : data?.hybRxn) : ''}
                </td>
                <td style={tdItemValueStyle}>
                    {data.itemOptions.getOptionString()}
                </td>
                <td style={tdItemValueStyle}>
                    {data.remark ? data.remark : '-'}
                </td>
            </tr>
        </React.Fragment>
    )
}

// const subTitleText = (index: number) => {
//     switch (index) {
//         case 0:
//             return '제조 예상 출하 가능일 입력';
//         case 1:
//             return '제조';
//         case 2:
//             return '포장';
//         default:
//             return '';
//     }
// }

@observer
class PrintManufactureRequestComponent extends React.Component<Props> {
    data = this.props.data;

    render() {
        return (
            <React.Fragment>
                <div style={{
                    breakInside: 'avoid',
                    breakAfter: 'auto',
                    width: '100%',
                }}>
                    <style type='text/css' media='print'>
                        {
                            `@page {
                                margin: 1cm;
                            }`
                        }
                    </style>
                    {/* main container */}
                    <Grid container direction='column' style={{ width: '100%' }}>
                        {/* logo, sign */}
                        <Grid
                            container
                            item
                            alignContent='flex-start' >
                            <Grid container item xs alignContent='center' alignItems='center'>
                                <Grid item xs>
                                    <img src={logo} style={{ height: 45, paddingLeft: 10 }} alt='Logo here' />
                                </Grid>
                            </Grid>
                            <Grid container item xs alignItems='center' style={{ border: '0px solid' }}>
                                <Grid container direction='column' alignContent='flex-end'>
                                    <table style={{ border: '1px solid', borderCollapse: 'collapse' }}>
                                        <tbody>
                                            <tr>
                                                <td style={{
                                                    border: '1px solid',
                                                    width: 90,
                                                    background: '#E2E2E2',
                                                    textAlign: 'center',
                                                    fontSize: tableValueFontSize,
                                                }}>
                                                    작성
                                                </td>
                                                <td style={{
                                                    border: '1px solid',
                                                    width: 90,
                                                    background: '#E2E2E2',
                                                    textAlign: 'center',
                                                    fontSize: tableValueFontSize,
                                                }}>
                                                    검토
                                                </td>
                                                <td style={{
                                                    border: '1px solid',
                                                    width: 90,
                                                    background: '#E2E2E2',
                                                    textAlign: 'center',
                                                    fontSize: tableValueFontSize,
                                                }}>
                                                    승인
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ height: 50, border: '1px solid', verticalAlign: 'center', textAlign: 'center' }}>
                                                    {this.data?.writerName}
                                                </td>
                                                <td style={{ height: 50, border: '1px solid' }}>

                                                </td>
                                                <td style={{ height: 50, border: '1px solid' }}>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    textAlign: 'center',
                                                    border: '1px solid',
                                                    fontSize: tableValueFontSize,
                                                }}>
                                                    {this.props?.data?.requestDate ?
                                                        dayjs(this.props.data?.requestDate).format('YYYY/MM/DD')
                                                        :
                                                        <span>
                                                            /&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/
                                                        </span>
                                                    }
                                                </td>
                                                <td style={{
                                                    textAlign: 'center',
                                                    border: '1px solid',
                                                    fontSize: tableValueFontSize,
                                                }}>
                                                    /&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/
                                                </td>
                                                <td style={{
                                                    textAlign: 'center',
                                                    border: '1px solid',
                                                    fontSize: tableValueFontSize,
                                                }}>
                                                    /&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* logo, sign end */}
                        {/* title */}
                        <Grid
                            container
                            item
                            direction='column'
                            alignItems='center'>
                            <Grid item xl lg sm style={{ marginTop: 5 }}>
                                <table style={{ width: 500 }}>
                                    <tbody>
                                        <tr>
                                            <td style={titleStyle}>
                                                <Typography variant='h6'>
                                                    <strong>
                                                        제조 의뢰서
                                                    </strong>
                                                </Typography>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Grid>
                        </Grid>
                        {/* title end */}
                        {/* 문서번호 */}
                        <Grid container alignItems='flex-start'>
                            <Grid item>
                                <Typography style={{ marginTop: tableMarginTop }} variant='body2'>
                                    {`문서번호: ${docNO}`}
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* 문서번호 end */}
                        {/* Request info */}
                        <Grid
                            container
                            item
                            direction='column'
                            alignItems='center'
                            style={{ marginTop: 4 }}>
                            <table style={{ ...mainTable, marginTop: 0 }}>
                                <colgroup>
                                    <col style={{ width: '25%' }} />
                                    <col style={{ width: '25%' }} />
                                    <col style={{ width: '25%' }} />
                                    <col style={{ width: '25%' }} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <td style={titleStyle} colSpan={4}>
                                            <span style={{ fontSize: tableTitleFontSize }}>
                                                <strong>
                                                    의뢰정보
                                                </strong>
                                            </span>

                                        </td>
                                    </tr>
                                    <tr>
                                        {
                                            keyValueItemCell(
                                                'representativeDepart',
                                                this.data?.representativeDepart
                                            )
                                        }
                                        {
                                            keyValueItemCell(
                                                'representativeName',
                                                this.data?.representativeName
                                            )
                                        }
                                    </tr>
                                    <tr>
                                        {
                                            keyValueItemCell(
                                                'writerDepart',
                                                '영업관리파트'
                                            )
                                        }
                                        {
                                            keyValueItemCell(
                                                'writerName',
                                                this.data?.writerName
                                            )
                                        }
                                    </tr>
                                    <tr>
                                        {
                                            keyValueItemCell(
                                                'projectCode',
                                                this.data?.projectCode
                                            )
                                        }
                                        {
                                            keyValueItemCell(
                                                'erpCode',
                                                this.data?.erpCode
                                            )
                                        }
                                    </tr>
                                    <tr>
                                        {
                                            keyValueItemCell(
                                                'customerAndPI',
                                                this.data?.customerAndPI
                                            )
                                        }
                                        {
                                            keyValueItemCell(
                                                'manufacturePurpose',
                                                `${(ManufactureWork.ManufacturePurposeList as any)[this.data?.manufacturePurpose] ?? ''}`
                                            )
                                        }
                                    </tr>
                                    <tr>
                                        {
                                            keyValueItemCell(
                                                'productDivision',
                                                `${(ManufactureWork.ProductDivisonList as any)[this.data?.productDivision] ?? ''}`
                                            )
                                        }
                                        {
                                            keyValueItemCell(
                                                '예상 출고 요청일시',
                                                `${this.data.determinedReleaseDate ? dayjs(this.data.determinedReleaseDate).format('YYYY/MM/DD') +
                                                    (this.data.determinedReleaseAMPM === 'pm' ? ' 오후' : ' 오전') : ''}`
                                            )
                                        }
                                    </tr>
                                    <tr>
                                        {
                                            keyValueItemCell(
                                                'coa',
                                                this.data?.coa ? 'YES' : 'NO'
                                            )
                                        }
                                        {
                                            keyValueItemCell(
                                                'productEducation',
                                                this.data?.productEducation ? 'YES' : 'NO'
                                            )
                                        }
                                    </tr>
                                </tbody>
                            </table>
                        </Grid>
                        {/* Request info end */}
                        {/* Customer Request info */}
                        <Grid
                            container
                            item
                            direction='column'
                            alignItems='center'
                            style={{ marginTop: 4 }}>
                            <table style={{ ...mainTable, marginTop: 0 }}>
                                <colgroup>
                                    <col style={{ width: '25%' }} />
                                    <col style={{ width: '75%' }} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <td style={{
                                            textAlign: 'center',
                                            border: '1px solid',
                                            padding: tablePadding,
                                            background: '#e2e2e2',
                                            fontSize: tableValueFontSize,
                                        }}>
                                            {FormUtil.getFieldLabel('customerRequest')}
                                        </td>
                                        <td style={{
                                            textAlign: 'left',
                                            border: '1px solid',
                                            padding: tablePadding,
                                            paddingLeft: 7,
                                            whiteSpace: 'pre-line',
                                            fontSize: tableValueFontSize,
                                        }}>
                                            <span>
                                                {this.data?.customerRequest}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            textAlign: 'center',
                                            border: '1px solid',
                                            padding: tablePadding,
                                            background: '#e2e2e2',
                                            fontSize: tableValueFontSize,
                                        }}>
                                            {FormUtil.getFieldLabel('legalCompliance')}
                                        </td>
                                        <td style={{
                                            textAlign: 'left',
                                            border: '1px solid',
                                            padding: tablePadding,
                                            fontSize: tableValueFontSize,
                                        }}>
                                            <Grid container style={{ paddingLeft: 0 }}>
                                                <Grid item xl lg sm>
                                                    <FormControlLabel
                                                        style={{ margin: 0 }}
                                                        control={
                                                            <Checkbox
                                                                style={{
                                                                    transform: 'scale(0.7)'
                                                                }}
                                                                checked={this.data.legalCompliance === null ? false : this.data?.legalCompliance}
                                                                name='legalCompliance' />
                                                        }
                                                        label={<span style={{ fontSize: tableValueFontSize }}>예</span>} />
                                                </Grid>
                                                <Grid item xl lg sm>
                                                    <FormControlLabel
                                                        style={{ margin: 0 }}
                                                        control={
                                                            <Checkbox
                                                                style={{
                                                                    transform: 'scale(0.7)'
                                                                }}
                                                                checked={this.data.legalCompliance === null ? false : !this.data?.legalCompliance}
                                                                name='legalCompliance' />
                                                        }
                                                        label={<span style={{ fontSize: tableValueFontSize }}>아니오</span>} />
                                                </Grid>
                                            </Grid>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            textAlign: 'center',
                                            border: '1px solid',
                                            padding: tablePadding,
                                            background: '#e2e2e2',
                                        }}>
                                            <span style={{ fontSize: tableValueFontSize }}>추가검토</span>
                                            <br />
                                            <span style={{ fontSize: 10 }}>
                                                (준수 여부 NO일 경우 작성)
                                            </span>
                                        </td>
                                        <td style={{
                                            textAlign: 'left',
                                            border: '1px solid',
                                            padding: tablePadding,
                                            paddingLeft: 7,
                                            whiteSpace: 'pre-line',
                                        }}>
                                            <span style={{ fontSize: tableValueFontSize }}>
                                                {this.data?.additionalReview}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Grid>
                        {/* Customer Request info end */}
                        {/* Item info */}
                        <Grid
                            container
                            item
                            alignItems='center'>
                            <table style={{
                                border: '1px solid',
                                borderCollapse: 'collapse',
                                width: '100%',
                                marginTop: tableMarginTop,
                            }}>
                                <colgroup>
                                    <col style={{ width: '*%' }} />
                                    <col style={{ width: '*%' }} />
                                    <col style={{ width: '*%' }} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <td style={titleStyle} colSpan={7}>
                                            <span style={{ fontSize: tableTitleFontSize }}>
                                                <strong>
                                                    아이템 정보
                                                </strong>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* Item list */}
                            <table style={{
                                width: '100%',
                                border: '1px solid',
                                borderTop: '0px solid',
                                borderCollapse: 'collapse',
                            }}>
                                <tbody>
                                    <tr>
                                        <td style={{
                                            ...tdItemKeyStyle,
                                            borderTop: '0px solid',
                                        }}>
                                            <strong>
                                                #
                                            </strong>
                                        </td>
                                        <td style={{
                                            ...tdItemKeyStyle,
                                            borderTop: '0px solid',
                                        }}>
                                            <strong>
                                                Platform
                                            </strong>
                                        </td>
                                        <td style={{
                                            ...tdItemKeyStyle,
                                            borderTop: '0px solid',
                                        }}>
                                            <strong>
                                                Items
                                            </strong>
                                        </td>
                                        <td style={{
                                            ...tdItemKeyStyle,
                                            borderTop: '0px solid',
                                        }}>
                                            <strong>
                                                Unit
                                            </strong>
                                        </td>
                                        <td style={{
                                            ...tdItemKeyStyle,
                                            borderTop: '0px solid',
                                        }}>
                                            <strong>
                                                Q'ty
                                            </strong>
                                        </td>
                                        <td style={{
                                            ...tdItemKeyStyle,
                                            borderTop: '0px solid',
                                            whiteSpace: 'pre-line'
                                        }}>
                                            <strong>
                                                {`Multi
                                                plexing`}
                                            </strong>
                                        </td>
                                        <td style={{
                                            ...tdItemKeyStyle,
                                            borderTop: '0px solid',
                                        }}>
                                            <strong>
                                                Hyb-rxn
                                            </strong>
                                        </td>
                                        <td style={{
                                            ...tdItemKeyStyle,
                                            borderTop: '0px solid',
                                        }}>
                                            <strong>
                                                Options
                                            </strong>
                                        </td>
                                        <td style={{
                                            ...tdItemKeyStyle,
                                            borderTop: '0px solid',
                                        }}>
                                            <strong>
                                                Remark
                                            </strong>
                                        </td>
                                    </tr>
                                    {
                                        this.data?.itemList.map((v, i) => {
                                            return (
                                                // <tr style={trPageBreak} key={i}>
                                                <React.Fragment key={`frag1212${i}`}>
                                                    {itemCell(v, i)}
                                                </React.Fragment>
                                                // </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            {/* Item list end */}
                        </Grid>
                        {/* Item info end */}
                        {/* Note */}
                        <Grid
                            item
                            xl
                            lg
                            sm>
                            <table style={{
                                ...mainTable,
                            }}>
                                <tbody>
                                    <tr style={{
                                        ...tdItemKeyStyle,
                                    }}>
                                        <td style={{ fontSize: '1.125rem', fontWeight: 'bold' }}>
                                            영업팀 NOTE
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            ...tdItemValueStyle,
                                            textAlign: 'start',
                                            height: 50,
                                            whiteSpace: 'pre',
                                        }}>
                                            {this.data?.note}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Grid>
                        {/* Note end */}
                    </Grid>
                </div>
            </React.Fragment>
        )
    }
}

export default PrintManufactureRequestComponent;