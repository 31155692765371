import { observer } from "mobx-react";
import { ManufactureItemOption } from "../../../../../data/model/manufacture/ManufactureItemOption";
import { Button, Grid } from "@material-ui/core";
import { ManufactureItemOptionRadioSelector, ManufactureItemOptionsCheckbox } from "./CommonComponents";

export interface ManufacturePresetInfoFormProps {
    presetItemOptions: ManufactureItemOption;
    onAdd: () => void;
    onGenerate: () => void;
}

const ManufacturePresetInfoForm: React.FC<ManufacturePresetInfoFormProps> = (props) => {
    const itemOptions = props.presetItemOptions;
    const onGenerate = props.onGenerate;
    const onAdd = props.onAdd;
    const readonly = false;

    return (
        <>
            <Grid container>
                <Grid item xl lg sm md>
                    <ManufactureItemOptionRadioSelector
                        optionKey="platform"
                        value={itemOptions?.platform}
                        selections={[
                            {
                                label: "Illumina",
                                value: "illumina"
                            },
                            {
                                label: "MGI",
                                value: "mgi"
                            },
                            {
                                label: "Ion Torrent",
                                value: "ionTorrent"
                            },
                        ]}
                        title="Platform"
                        disabled={readonly}
                        error={itemOptions?.errors.platform.isError}
                        errorText={itemOptions?.errors.platform.errorText}
                        onValueChange={itemOptions?.onChangeOptionValue}
                    />
                </Grid>
                <Grid item xl lg sm md>
                    <ManufactureItemOptionRadioSelector
                        optionKey="lpep"
                        value={itemOptions?.lpep}
                        selections={[
                            {
                                label: "LP",
                                value: "lp"
                            },
                            {
                                label: "EP",
                                value: "ep"
                            },
                        ]}
                        title="LP/EP"
                        disabled={readonly || itemOptions?.platform === null}
                        error={itemOptions?.errors.lpep.isError}
                        errorText={itemOptions?.errors.lpep.errorText}
                        onValueChange={itemOptions?.onChangeOptionValue}
                    />
                </Grid>
                <Grid item xl lg sm md>
                    <ManufactureItemOptionRadioSelector
                        disabled={readonly || (itemOptions?.platform ? false : true)}
                        optionKey="indexType"
                        value={itemOptions?.indexType}
                        selections={
                            itemOptions?.platform ?
                                itemOptions?.getIndexTypeList().map(v => {
                                    return {
                                        label: itemOptions?.translateIndex(v.value),
                                        value: v.value,
                                    }
                                })
                                :
                                []
                        }
                        title="Index"
                        error={itemOptions?.errors.indexType.isError}
                        errorText={itemOptions?.errors.indexType.errorText}
                        onValueChange={itemOptions?.onChangeOptionValue}
                    />
                </Grid>
                <Grid item xl lg sm md>
                    <ManufactureItemOptionRadioSelector
                        disabled={readonly || (itemOptions?.platform ? false : true)}
                        optionKey="adapterType"
                        value={itemOptions?.adapterType}
                        selections={
                            itemOptions?.platform ?
                                itemOptions?.getAdapterList().map(v => {
                                    return {
                                        label: itemOptions?.translateAdapter(v.value),
                                        value: v.value,
                                    }
                                })
                                :
                                []
                        }
                        title="Adapter"
                        error={itemOptions?.errors.adapterType.isError}
                        errorText={itemOptions?.errors.adapterType.errorText}
                        onValueChange={itemOptions?.onChangeOptionValue}
                    />
                </Grid>
                <Grid item xl lg sm md>
                    <ManufactureItemOptionsCheckbox
                        disabled={readonly || (itemOptions?.platform ? false : true)}
                        optionKey="tc1Enhancer"
                        value={itemOptions?.tc1Enhancer}
                        title="Methyl enhancer"
                        error={itemOptions?.errors.tc1Enhancer.isError}
                        errorText={itemOptions?.errors.tc1Enhancer.errorText}
                        onValueChange={itemOptions?.onChangeOptionValue}
                    />
                </Grid>
                <Grid item xl lg sm md>
                    <ManufactureItemOptionsCheckbox
                        disabled={readonly || (itemOptions?.platform ? false : true)}
                        optionKey="tc2Enhancer"
                        value={itemOptions?.tc2Enhancer}
                        title="Hyb enhancer"
                        error={itemOptions?.errors.tc2Enhancer.isError}
                        errorText={itemOptions?.errors.tc2Enhancer.errorText}
                        onValueChange={itemOptions?.onChangeOptionValue}
                    />
                </Grid>
                <Grid item xl lg sm md>
                    <ManufactureItemOptionRadioSelector
                        disabled={readonly}
                        optionKey="probeState"
                        value={itemOptions?.probeState}
                        selections={[
                            {
                                label: "Solution",
                                value: "solution"
                            },
                            {
                                label: "Powder",
                                value: "powder"
                            },
                        ]}
                        title="Probe state"
                        error={itemOptions?.errors.probeState.isError}
                        errorText={itemOptions?.errors.probeState.errorText}
                        onValueChange={itemOptions?.onChangeOptionValue}
                    />
                </Grid>
                {itemOptions.version === 'v3' &&
                    <Grid item xl lg sm md>
                        <ManufactureItemOptionRadioSelector
                            disabled={readonly}
                            optionKey="probeConc"
                            value={itemOptions?.probeConc}
                            selections={[
                                {
                                    label: "High (기본)",
                                    value: "high"
                                },
                                {
                                    label: "Low (Single-plexing, size 1백만kb 이상)",
                                    value: "low"
                                },
                                {
                                    label: "Methyl",
                                    value: "methyl"
                                },
                                {
                                    label: "etc.",
                                    value: "etc"
                                },
                            ]}
                            title="Probe Conc."
                            error={itemOptions?.errors.probeConc.isError}
                            errorText={itemOptions?.errors.probeConc.errorText}
                            onValueChange={itemOptions?.onChangeOptionValue}
                        />
                    </Grid>
                }
            </Grid>
            <Grid container style={{ marginTop: 10 }} spacing={1}>
                <Grid item>
                    <Button
                        onClick={onGenerate}
                        color='primary'
                        variant='contained'>생성</Button>
                </Grid>
                <Grid item>
                    <Button
                        onClick={onAdd}
                        color='primary'
                        variant='contained'>추가</Button>
                </Grid>
            </Grid>
            <hr />
        </>
    )
}

export default observer(ManufacturePresetInfoForm);