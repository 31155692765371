import React from 'react';
import TaskFormProps from '../TaskFormProps';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Grid, Typography, TextField, Button, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import Synth3Store from '../../../../../../../../data/store/pageStore/design/Synth3Store';
import Helper from '../../../../../../../../module/Helper';
import TitledPaper from '../../../../../../../common/component/TitledPaper';
import FormUtil from '../../../../../../../../module/FormUtil';
import FormButtonSet from '../../../../../../../common/component/FormButtonSet';
import PTSMessage from '../../../../../../../../data/model/common/PTSMessage';
import Validatable from '../../../../../../../../data/model/common/Validatable';
import FileWithHelperText from '../../../../../../../common/component/FileWithHelperText';
import TableWithHelperText from '../../../../../../../common/component/TableWithHelperText';
import TaskInfo from '../../../../../../../../resource/data/tasks.json';

interface Props extends TaskFormProps, RouteComponentProps<any> {
}

@inject('rootStore')
@observer
class Synth3Form extends React.Component<Props> {
    globalStore = this.props.rootStore.globalStore;
    store = observable(new Synth3Store());
    state = {
        workID: 0,
        sdContentsString: '',
    }

    componentDidMount = async () => {
        try {
            this.globalStore.isRunning = true;
            const workInfo = this.props.workInfo;
            this.store.workInfo = workInfo;
            this.store.taskData._id = (workInfo.taskList[0] as any)._id;
            this.store.taskData.workID = (workInfo.taskList[0] as any).workID;
            this.props.rootStore.commentStore.stage = '합성 Ph.3';
            const synthDesign = this.store.workInfo.taskList.filter((v: any) => {
                return v.type === TaskInfo.types.synthDesign
                    || v.type === TaskInfo.types.rebalancingSynthDesign;
            })[0];
            this.store.panelName = (synthDesign as any).detail?.panelName ?? '';
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        } finally {
            this.globalStore.isRunning = false;
        }
    }

    getFileUploadForm = (): FormData => {
        const formData = new FormData();
        const fe = Array.from(this.store.files.values());
        for (const file of fe) {
            formData.append('files', file);
        }
        return formData;
    }

    onFileChange = (name: string, files: FileList) => {
        const file = files[0];
        const uniqueKey = Helper.generateRandomString();
        const ptsFileObj = Helper.generatePTSFileWithUniqueKey(file, uniqueKey);
        this.store.files.set(name, file);
        this.store.filesMapping.set(name, ptsFileObj);
        return ptsFileObj;
    }

    onValidate = () => {
        this.store.taskData.detail.initErrorsRecursive();
        this.store.taskData.detail.resetErrorsRecursive();
        this.store.taskData.detail.validateRecursive();
        this.store.isValid = !this.store.taskData.detail.hasErrorRecursive();
        if (!this.store.isValid) {
            alert(Validatable.errorTexts.notValid);
        }
    }

    onFormClear = () => {
        window.location.reload();
    }

    onFormSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        try {
            if (!window.confirm('제출하시겠습니까?')) {
                return;
            }
            this.globalStore.isRunning = true;

            // upload files
            const files = this.store.getFileUploadForm();
            if (files) {
                const fileResult = await this.store.uploadFiles(files, this.globalStore.token);
                const mapping = Array.from(this.store.filesMapping.values());

                for (let i = 0; i < fileResult.data.data.length; i++) {
                    mapping[i].file_id = fileResult.data.data[i];
                }
            }

            // const chipResult = await this.store.replaceChipInfos(this.globalStore.token);
            // if (chipResult.data.type === 'apiErr') {
            //     throw new PTSMessage({
            //         message: chipResult.data.resMessage,
            //     })
            // }

            delete this.store.taskData.detail.sdContentsError;
            this.props.postTaskData(this.store.taskData);
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        } finally {
            this.globalStore.isRunning = false;
        }
    }

    handleSDContents = (e: React.MouseEvent<HTMLElement>) => {
        const value = this.state.sdContentsString;
        const split = value.split(/[\t\n\s]/);
        const list: string[] = [];
        split.map((v) => {
            if (v) {
                list.push(v.trim());
            }
            return null;
        });
        this.store.taskData.detail.sdContents = list;
    }

    handleSDContentsClear = (e: React.MouseEvent<HTMLElement>) => {
        this.setState({
            ...this.state,
            sdContentsString: '',
        });
        this.store.taskData.detail.sdContents = [];
    }

    render() {
        return (
            <React.Fragment>
                <Grid item container>
                    <Grid item xl lg sm>
                        <TitledPaper title='Datas'>
                            <Grid item container xl lg sm style={{ padding: 10 }}>
                                <TextField
                                    fullWidth
                                    margin='normal'
                                    value={this.store.taskData.detail.probeID || ''}
                                    name='probeID'
                                    onChange={this.store.taskData.detail.onChangeDefault}
                                    label={FormUtil.getFieldLabel('probeID')}
                                    helperText={
                                        Helper.getErrorText(
                                            this.store.taskData.detail.errors.probeID
                                        )
                                    }
                                    error={
                                        Helper.getErrorState(
                                            this.store.taskData.detail.errors.probeID
                                        )
                                    } />
                                <TextField
                                    fullWidth
                                    margin='normal'
                                    name='panelName'
                                    value={this.store.panelName}
                                    InputProps={{ readOnly: true }}
                                    onChange={this.store.taskData.detail.onChangeDefault}
                                    label={FormUtil.getFieldLabel('panelName')} />
                                <TextField
                                    fullWidth
                                    margin='normal'
                                    value={this.store.taskData.detail.probeName || ''}
                                    name='probeName'
                                    onChange={this.store.taskData.detail.onChangeDefault}
                                    label={FormUtil.getFieldLabel('probeName')}
                                    helperText={
                                        Helper.getErrorText(
                                            this.store.taskData.detail.errors.probeName
                                        )
                                    }
                                    error={
                                        Helper.getErrorState(
                                            this.store.taskData.detail.errors.probeName
                                        )
                                    } />
                                <TextField
                                    fullWidth
                                    margin='normal'
                                    value={this.store.taskData.detail.probeConc || ''}
                                    name='probeConc'
                                    type='number'
                                    onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                                    onChange={this.store.taskData.detail.onChangeDefault}
                                    label={FormUtil.getFieldLabel('probeConc')}
                                    helperText={
                                        Helper.getErrorText(
                                            this.store.taskData.detail.errors.probeConc
                                        )
                                    }
                                    error={
                                        Helper.getErrorState(
                                            this.store.taskData.detail.errors.probeConc
                                        )
                                    } />
                                <TextField
                                    fullWidth
                                    margin='normal'
                                    value={this.store.taskData.detail.dilutionFactor}
                                    name='dilutionFactor'
                                    type='number'
                                    onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                                    onChange={this.store.taskData.detail.onChangeDefault}
                                    label={FormUtil.getFieldLabel('dilutionFactor')}
                                    helperText={
                                        Helper.getErrorText(
                                            this.store.taskData.detail.errors.dilutionFactor
                                        )
                                    }
                                    error={
                                        Helper.getErrorState(
                                            this.store.taskData.detail.errors.dilutionFactor
                                        )
                                    } />
                                <TextField
                                    fullWidth
                                    margin='normal'
                                    value={this.store.taskData.detail.origProbeVol || ''}
                                    name='origProbeVol'
                                    onChange={this.store.taskData.detail.onChangeDefault}
                                    label={FormUtil.getFieldLabel('origProbeVol')}
                                    helperText={
                                        Helper.getErrorText(
                                            this.store.taskData.detail.errors.origProbeVol
                                        )
                                    }
                                    error={
                                        Helper.getErrorState(
                                            this.store.taskData.detail.errors.origProbeVol
                                        )
                                    } />
                                <TextField
                                    fullWidth
                                    margin='normal'
                                    value={this.store.taskData.detail.finalAmount || ''}
                                    name='finalAmount'
                                    onChange={this.store.taskData.detail.onChangeDefault}
                                    label={FormUtil.getFieldLabel('finalAmount')}
                                    helperText={
                                        Helper.getErrorText(
                                            this.store.taskData.detail.errors.finalAmount
                                        )
                                    }
                                    error={
                                        Helper.getErrorState(
                                            this.store.taskData.detail.errors.finalAmount
                                        )
                                    } />
                                <TextField
                                    fullWidth
                                    margin='normal'
                                    value={this.store.taskData.detail.boxNumber || ''}
                                    name='boxNumber'
                                    // type='number'
                                    onChange={this.store.taskData.detail.onChangeDefault}
                                    label={FormUtil.getFieldLabel('boxNumber')}
                                    helperText={
                                        Helper.getErrorText(
                                            this.store.taskData.detail.errors.boxNumber
                                        )
                                    }
                                    error={
                                        Helper.getErrorState(
                                            this.store.taskData.detail.errors.boxNumber
                                        )
                                    } />
                                <Grid item xl={4} lg={4} sm={4} style={{ marginTop: 20 }}>
                                    <Typography variant='body1'>
                                        {FormUtil.getFieldLabel('probeTapeStationFile')}
                                    </Typography>
                                </Grid>
                                <Grid item xl={8} lg={8} sm={8} style={{ marginTop: 20 }}>
                                    <FileWithHelperText
                                        name='probeTapeStationFile'
                                        multiple={false}
                                        onFileChange={this.onFileChange}
                                        data={this.store.taskData.detail}
                                        helperText={
                                            Helper.getErrorText(
                                                this.store.taskData.detail.errors.probeTapeStationFile
                                            )
                                        }
                                        error={
                                            Helper.getErrorState(
                                                this.store.taskData.detail.errors.probeTapeStationFile
                                            )
                                        } />
                                </Grid>
                                <Grid item xl={4} lg={4} sm={4} style={{ marginTop: 20 }}>
                                    <Typography variant='body1'>
                                        {FormUtil.getFieldLabel('poolingExcelFile')}
                                    </Typography>
                                </Grid>
                                <Grid item xl={8} lg={8} sm={8} style={{ marginTop: 20 }}>
                                    <FileWithHelperText
                                        name='poolingExcelFile'
                                        multiple={false}
                                        onFileChange={this.onFileChange}
                                        data={this.store.taskData.detail}
                                        helperText={
                                            Helper.getErrorText(
                                                this.store.taskData.detail.errors.poolingExcelFile
                                            )
                                        }
                                        error={
                                            Helper.getErrorState(
                                                this.store.taskData.detail.errors.poolingExcelFile
                                            )
                                        } />
                                </Grid>
                            </Grid>
                            <TitledPaper title={FormUtil.getFieldLabel('sdContents')}>
                                <Grid xl lg sm item container alignItems='stretch'>
                                    <Grid xl={10} lg={10} sm={10} container item alignItems='stretch'>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={5}
                                            margin='normal'
                                            label={FormUtil.getFieldLabel('sdContents')}
                                            onChange={(e) => this.setState({ ...this.state, sdContentsString: e.target.value })}
                                            value={this.state.sdContentsString}
                                            helperText={
                                                Helper.getErrorText(
                                                    this.store.taskData.detail.errors.sdContents
                                                )
                                            }
                                            error={
                                                Helper.getErrorState(
                                                    this.store.taskData.detail.errors.sdContents
                                                )
                                            } />
                                    </Grid>
                                    <Grid spacing={1} style={{ margin: 10 }} xl lg sm container item direction='column' alignItems='stretch'>
                                        <Grid spacing={1} xl lg sm item container alignItems='stretch'>
                                            <Button
                                                style={{ margin: 5 }}
                                                fullWidth
                                                onClick={(e) => this.handleSDContents(e)}
                                                variant='contained'
                                                color='primary'>적용</Button>
                                            <Button
                                                style={{ margin: 5 }}
                                                fullWidth
                                                variant='contained'
                                                onClick={(e) => this.handleSDContentsClear(e)}
                                                color='secondary'>비우기</Button>
                                        </Grid>
                                    </Grid>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    {FormUtil.getFieldLabel('sdContents')}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.store.taskData.detail.sdContents.map((v, i) => (
                                                <TableWithHelperText
                                                    key={i}
                                                    index={i}
                                                    data={v}
                                                    helperText={
                                                        this.store.taskData.detail.sdContentsError[i]
                                                    }
                                                    error={
                                                        this.store.taskData.detail.sdContentsError[i] === '' || true
                                                    } />
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </TitledPaper>
                        </TitledPaper>
                        {/* <Grid item xl lg sm>
                            <TitledPaper title='Chip Infos'>
                                <Grid xl lg sm item container direction='column'>
                                    {this.store.chipInfos.map((v, i) => (
                                        <Synth3InputCell data={v} index={i} key={i} />
                                    ))}
                                </Grid>
                            </TitledPaper>
                        </Grid> */}
                    </Grid>
                </Grid>
                <Grid item xl lg sm>
                    <FormButtonSet
                        validated={this.store.isValid}
                        onFormClear={(e) => this.onFormClear()}
                        onSubmit={(e) => this.onFormSubmit(e)}
                        onValidate={(e) => this.onValidate()}
                    />
                </Grid>
            </React.Fragment>
        )
    }
}

export default withRouter(Synth3Form);