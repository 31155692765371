import React from 'react';
import { observer } from 'mobx-react';
import Validatable from '../../../data/model/common/Validatable';
import ModelBase from '../../../data/model/common/ModelBase';
import FormUtil from '../../../module/FormUtil';
import { StandardTextFieldProps, TextField } from '@material-ui/core';
import Helper from '../../../module/Helper';
import ManufactureWork from '../../../data/model/manufacture/ManufactureWork';

interface Props extends StandardTextFieldProps {
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    readOnly?: boolean;
    translation?: (str: string) => string;
    useValueTranslate?: boolean;
    rootdata: ModelBase | Validatable;
    name: string;
}

const ExtendedMUISTextField: React.FC<Props> = observer((props) => {
    const onChange = props.onChange
        || props.rootdata.onChangeDefault
        || ((e: React.ChangeEvent<HTMLInputElement>) => console.log('onChange not defined'));

    const readOnly = (props.readOnly === undefined || props.readOnly === null) ?
        false
        :
        props.readOnly;

    const translation = props.translation || FormUtil.getFieldLabel;
    const useValueTranslate = props.useValueTranslate || false;

    return (
        <TextField
            name={props.name}
            label={translation(props.name)}
            onChange={onChange}
            value={
                useValueTranslate
                    ?
                    translation(props.value as string)
                    :
                    props.value
            }
            error={
                props.rootdata instanceof Validatable || props.rootdata instanceof ManufactureWork
                    ?
                    Helper.getErrorState(props.rootdata.errors[props.name])
                    :
                    props.error
            }
            helperText={
                props.rootdata instanceof Validatable || props.rootdata instanceof ManufactureWork
                    ?
                    Helper.getErrorText(props.rootdata.errors[props.name])
                    :
                    props.helperText
            }
            InputProps={{
                readOnly: readOnly
            }}
            {...props}
        />
    )
})

export default ExtendedMUISTextField;