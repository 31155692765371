import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Grid, Button, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, FormHelperText, InputLabel } from '@material-ui/core';
import { observable } from 'mobx';
import ManufactureItemFormRow from './ManufactureItemFormRow';
import { Close } from '@material-ui/icons';
import ManufactureWork from '../../../../../data/model/manufacture/ManufactureWork';
import ManufactureItemFormStore from '../../../../../data/store/pageStore/manufacture/ManufactureItemFormStore';
import ManufactureItem from '../../../../../data/model/manufacture/ManufactureItem';
import PTSTable from '../../../../common/component/task/table/PTSTable';
import ManufacturePanel from '../../../../../data/model/manufacture/ManufacturePanel';
import PresetSelectComponent from './PresetSelectComponent';
import ManufacturePresetInfoForm from './ManufacturePresetInfoForm';
import { ManufactureItemOption } from '../../../../../data/model/manufacture/ManufactureItemOption';
import { ItemVersion } from '@celemicsbisw/manufacture-item-structure';

interface Props {
    isReworkIndex?: boolean;
    panelList?: ManufacturePanel[];
    data: ManufactureWork;
    readonly?: boolean;
    store: ManufactureItemFormStore;
}

let rows: JSX.Element[] = observable([]);

const ManufactureRequestItemsForm: React.FC<Props> = observer((props) => {
    const isReworkIndex = props.isReworkIndex;
    const panelList = props.panelList;
    const data = props.data;
    const store = props.store;
    store.itemList = props.data.itemList;

    useEffect(() => {
        if (isReworkIndex) {
            store.clearIndexList();
            store.setIndexListByProductType(data.productType);
            renderRows();
        }
    }, [isReworkIndex]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        renderRows();
    }, [store.itemList]); // eslint-disable-line react-hooks/exhaustive-deps

    // 테이블 해더
    const inputHeaders = [
        ManufactureItem.HeaderKeyList.itemName as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.numberPerQty as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.unit as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.qty as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.pooling as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.hybRxn as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.probeID as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.remark as keyof ManufactureItem,
    ]

    store.inputHeaders = inputHeaders;

    const readonlyHeaders: (keyof ManufactureItem)[] = [];

    const addNewEmptyItem = () => {
        const item = new ManufactureItem();
        item.itemOptions.version = data.manufactureVersion;
        item.validateList = inputHeaders;
        store.itemList.push(item);
        renderRows();
        return;
    }

    const renderDeleteButton = (index: number): JSX.Element => {
        return (
            <Button>
                <Close
                    color='secondary'
                    onClick={
                        () => {
                            store.deleteItem(index);
                            renderRows();
                        }
                    }
                />
            </Button>
        )
    }

    const renderRows = () => {
        while (rows.length > 0) {
            rows.pop();
        }
        store.itemList.map((v, i) => {
            rows.push(
                <ManufactureItemFormRow
                    key={`manitem${i}`}
                    inputHeaders={inputHeaders}
                    readonlyHeaders={readonlyHeaders}
                    data={v}
                    productType={data.productType}
                    buttons={[renderDeleteButton(i)]}
                    index={i}
                    work={data}
                    panelList={panelList}
                />
            )
            return null;
        })
    }

    const headerTrans = (str: string): string => {
        return (ManufactureItem.HeaderList as any)[str] || str;
    }

    return (
        <React.Fragment>
            <Grid container direction='column'>
                <Grid item xl lg md style={{ marginTop: 10 }}>
                    <Grid item xl lg sm md>
                        <FormControl
                            disabled={readonlyHeaders.length > 0}
                            component="fieldset"
                            error={data.errors.manufactureVersion.isError}>
                            <FormLabel component="legend">
                                Version
                            </FormLabel>
                            <RadioGroup
                                row
                                onChange={e => {
                                    // store.clearItems();
                                    store.resetPreset();
                                    data.onChangeDefault(e);
                                    store.itemVersion = e.target.value as ItemVersion;
                                }}
                                aria-label="manufactureVersion"
                                name="manufactureVersion"
                                value={data.manufactureVersion}>
                                <FormControlLabel
                                    value="v1"
                                    control={<Radio />} label="V1" />
                                <FormControlLabel
                                    value="v2"
                                    control={<Radio />} label="V2" />
                                <FormControlLabel
                                    value="v3"
                                    control={<Radio />} label="V3" />
                            </RadioGroup>
                            <FormHelperText>{data.errors.manufactureVersion.errorText}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xl={6} lg={6} sm={6} md={6} style={{ marginBottom: 20, marginTop: 20 }}>
                    <FormControl fullWidth>
                        <InputLabel id='selectedItemPreset'>
                            Item Preset
                        </InputLabel>
                        <PresetSelectComponent
                            disabled={data.manufactureVersion === null ? true : false}
                            onPresetChange={(e) => {
                                store.presetItemOptions = new ManufactureItemOption(false, 'preset');
                                store.itemVersion = data.manufactureVersion;
                                store.presetItemOptions.version = data.manufactureVersion;
                                store.onChangeDefault(e);
                                if (e.target.value === 'custom') {
                                    store.presetLoaded = false;
                                }
                            }}
                            selectedItemPreset={store.selectedItemPreset}
                            itemPresetList={store.itemPresetList} />
                    </FormControl>
                </Grid>
                {
                    (store.selectedItemPreset !== 'custom') &&
                    <ManufacturePresetInfoForm
                        onGenerate={() => {
                            store.onPresetItemGenerate('gen');
                            store.itemList.forEach(v => v.validateList = inputHeaders);
                            renderRows();
                        }}
                        onAdd={() => {
                            store.onPresetItemGenerate('add');
                            store.itemList.forEach(v => v.validateList = inputHeaders);
                            renderRows();
                        }}
                        presetItemOptions={store.presetItemOptions} />
                }
                <Grid item xl lg md>
                    <PTSTable
                        headerTranslation={headerTrans}
                        headers={inputHeaders}
                        align='inherit'
                        useindex
                        rows={rows}
                        preRenderRows
                        additionalHeaders={[""]}
                    />
                </Grid>
                <Button
                    // disabled={data.productType === ''}
                    onClick={addNewEmptyItem}
                    style={{
                        marginTop: 30
                    }}
                    disabled={data.manufactureVersion === null ? true : false}
                    variant='contained'
                    color='primary'>
                    아이템 추가
                </Button>
            </Grid>
        </React.Fragment>
    )
})

export default ManufactureRequestItemsForm;
